import axios from 'axios';
import { PayByLinkResponse, PaymentTransaction, SessionConfig, User, } from './models';
import { RFPaymentResponse } from './rf_models';

export const API_GATEWAY_HOST = process.env.REACT_APP_SERVER_PATH;
export const CLIENT_CHANNEL = process.env.REACT_APP_CLIENT_CHANNEL;

export const formatRequestPath = (...args: any) => {
    const str = args[0];
    const params = args.filter((arg, index) => index !== 0);
    if (!str) return '';
    return str.replace(/%s[0-9]+/g, (matchedStr) => {
        const variableIndex = matchedStr.replace('%s', '') - 1;
        return params[variableIndex];
    });
};

const AUTH_BASE = '/api/auth';
const PAYMENT_BASE = '/api/payment';

export const API_PATHS = {
    AUTH_USER_ME: AUTH_BASE + '/user/me',
    AUTH_TOKEN_FORM: AUTH_BASE + '/token-form',
    AUTH_TOKEN: AUTH_BASE + '/token',
    AUTH_TOKEN_REFRESH: AUTH_BASE + '/refresh',
    CREATE_OPERATOR: AUTH_BASE + '/operator/create',
    CREATE_CUSTOMER: AUTH_BASE + '/customer/create',
    GET_CUSTOMER_LIST: AUTH_BASE + '/customer/view',

    OPERATOR_PASSWORD_RESET_SELF: AUTH_BASE + '/operator/password',
    OPERATOR_FORGOT_PASSWORD: AUTH_BASE + '/operator/forgot-password',
    OPERATOR_RESET_PASSWORD: AUTH_BASE + '/operator/reset-password',
    GET_OPERATOR_CLIENT_CHANNEL: AUTH_BASE + '/operator/lookup/client-channels',
    GET_OPERATOR_ALL_CLIENT_CHANNEL: AUTH_BASE + '/operator/lookup/all-channels',

    CREATE_PAYMENT_TRANSACTION: PAYMENT_BASE + '/v1/payment/payment-transaction',
    CANCEL_PAYMENT_TRANSACTION: PAYMENT_BASE + '/v1/payment/payment-transaction/customer-id/%s1/transaction-id/%s2/cancel',
    UPDATE_PAYMENT_TRANSACTION: PAYMENT_BASE + '/v1/payment/payment-transaction/customer-id/%s1/transaction-id/%s2',
    GET_PAYMENT_TRANSACTION: PAYMENT_BASE + '/v1/payment/payment-transaction/customer-id/%s1/transaction-id/%s2',
    GET_PAYMENT_TRANSACTION_NOAUTH: PAYMENT_BASE + '/v1/payment/payment-transaction/transaction-id/%s1',
    PAYMENT_GET_SESSION: PAYMENT_BASE + '/v1/payment/payment-transaction/retrieve-rf-session',
    UPDATE_PAYMENT_TRANSACTION_RF_VALUES: PAYMENT_BASE + '/v1/payment/payment-transaction/rf-update/transaction-id/%s1',
    SEND_PAYBYLINK_EMAIL: PAYMENT_BASE + '/v1/payment/payment-transaction/customer-id/%s1/transaction-id/%s2/pay-by-link',
    CUSTOM_REPORT_EXPORT: PAYMENT_BASE + '/v1/export/retrieve-report',
    CLIENT_CODE: PAYMENT_BASE + '/v1/export/client-code',
    GET_CLIENT_CODES: PAYMENT_BASE + '/v1/export/get_client_code',
    GET_BILLINGCONTACT_CODES: PAYMENT_BASE + '/v1/export/get_billing_contact',
    GET_DASHBOARD_GRAPH: PAYMENT_BASE + '/v1/dashboard/dashboard_graph/day',
    GET_DASHBOARD_EXPORT: PAYMENT_BASE + '/v1/dashboard/dashboard_report/day',
    POST_CONTACTFORM: AUTH_BASE + '/operator/contactsupport'
};

export const axiosPublic = axios.create({
    baseURL: API_GATEWAY_HOST,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const axiosPrivate = axios.create({
    baseURL: API_GATEWAY_HOST,
    headers: {
        'Content-Type': 'application/json',
    },
});

let axiosPrivateCommon = axios.create({
    baseURL: API_GATEWAY_HOST,
    headers: {
        'Content-Type': 'application/json',
    },
});

export function handleAuthCommon() {
    const storedAuth = localStorage.getItem('auth');
    const userToken = storedAuth ? JSON.parse(storedAuth) : null;
    if (userToken.accessToken) {
        axiosPrivateCommon.defaults.headers['Authorization'] = `Bearer ${userToken.accessToken}`;
    } else {
        delete axiosPrivateCommon.defaults.headers['Authorization']
    }
}

export async function fetchCurrentUserDetails(): Promise<User> {
    try {
        const response = await axiosPrivate.get(API_PATHS.AUTH_USER_ME);
        return response.data.user as User;
    } catch (error) {
        throw error; // Rethrow the error to propagate it
    }
}

export function handleClientChannel(channelId: string) {
    axiosPrivate.defaults.headers['x-client-channel'] = channelId;
}

export async function createPaymentTransaction(
    payload: any,
    channelId: any
): Promise<PaymentTransaction> {
    try {
        handleAuthCommon();
        axiosPrivateCommon.defaults.headers['x-client-channel'] = channelId;
        const response = await axiosPrivateCommon.post(
            API_PATHS.CREATE_PAYMENT_TRANSACTION,
            payload
        );
        return response.data as PaymentTransaction;
    } catch (error) {
        throw error;
    }
}

export function handleAuth(axiosPrivateUser: any) {
    const storedAuth = localStorage.getItem('auth');
    const userToken = storedAuth ? JSON.parse(storedAuth) : null;
    if (userToken.accessToken) {
        axiosPrivateUser.defaults.headers['Authorization'] = `Bearer ${userToken.accessToken}`;
    } else {
        delete axiosPrivateUser.defaults.headers['Authorization']
    }
}

export async function sendPayByLinkForTransaction(
    customer_id: string,
    transaction_id: string,
    channelId: any
): Promise<PayByLinkResponse> {
    let axiosPrivateUser = axios.create({
        baseURL: API_GATEWAY_HOST,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    axiosPrivateUser.defaults.headers['x-client-channel'] = channelId;
    handleAuth(axiosPrivateUser);
    const response = await axiosPrivateUser.post(
        formatRequestPath(
            API_PATHS.SEND_PAYBYLINK_EMAIL,
            customer_id,
            transaction_id
        )
    );
    return response.data as PayByLinkResponse;
}

export async function getPaymentTransactionNoAuth(
    transaction_id: string
): Promise<PaymentTransaction> {
    const response = await axiosPublic.get(
        formatRequestPath(API_PATHS.GET_PAYMENT_TRANSACTION_NOAUTH, transaction_id)
    );
    return response.data as PaymentTransaction;
}

export async function getCustomerList(
): Promise<any> {
    try {
        handleAuthCommon()
        const response = await axiosPrivateCommon.get(API_PATHS.GET_CUSTOMER_LIST);
        return response.data as any;
    } catch (error) {
        throw error;
    }
}

export async function getClientChannel(
    operator_id: string
): Promise<any> {
    const response = await axiosPrivate.get(
        `${API_PATHS.GET_OPERATOR_CLIENT_CHANNEL}?operator_id=${operator_id}`
    );
    return response.data as any;
}

export async function getAllClientChannel(
): Promise<any> {
    const response = await axiosPrivate.get(
        API_PATHS.GET_OPERATOR_ALL_CLIENT_CHANNEL
    );
    return response.data as any;
}

export async function fetchSessionConfig(channelId: string): Promise<SessionConfig> {
    try {
        handleAuthCommon();
        axiosPrivateCommon.defaults.headers['x-client-channel'] = channelId;
        const response = await axiosPrivateCommon.get(API_PATHS.PAYMENT_GET_SESSION);
        return response.data as SessionConfig;
    } catch (error) {
        throw error;
    }
}

export async function operatorSelfPasswordReset(current_password: string, password: string): Promise<any> {
    let axiosPrivateUser = axios.create({
        baseURL: API_GATEWAY_HOST,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    handleAuth(axiosPrivateUser);
    const response = await axiosPrivateUser.post(API_PATHS.OPERATOR_PASSWORD_RESET_SELF, {
        "current_password": current_password,
        "password": password
    });
    return response.data;
}

export async function operatorForgotPassword(email: string): Promise<any> {
    const response = await axiosPrivate.post(API_PATHS.OPERATOR_FORGOT_PASSWORD, {
        "email": email
    });
    return response.data;
}

export async function operatorResetPassword(secret_token: string, new_password: string, confirm_password: string): Promise<any> {
    const response = await axiosPrivate.post(API_PATHS.OPERATOR_RESET_PASSWORD, {
        "secret_token": secret_token,
        "new_password": new_password,
        "confirm_password": confirm_password
    });
    return response.data;
}

export async function createOperator(createOperatorPayload: any): Promise<any> {
    handleAuthCommon();
    const response = await axiosPrivateCommon.post(API_PATHS.CREATE_OPERATOR, createOperatorPayload);
    return response.data;
}

export async function createCustomer(auth, createCustomerPayload: any): Promise<any> {
    const axiosPrivateFormData = axios.create({
        baseURL: API_GATEWAY_HOST,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth.accessToken}`
        }
    });

    try {
        const response = await axiosPrivateFormData.post(API_PATHS.CREATE_CUSTOMER, createCustomerPayload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function updatePaymentTransactionForRFValues(
    transaction_id: string,
    rf_payment_response: RFPaymentResponse
): Promise<PayByLinkResponse> {
    const response = await axiosPrivate.post(
        formatRequestPath(
            API_PATHS.UPDATE_PAYMENT_TRANSACTION_RF_VALUES,
            transaction_id
        ),
        {
            rf_payin_id: rf_payment_response.payin_id,
            rf_payin_config_id: rf_payment_response.payin_config_id,
            rf_payin_status: rf_payment_response.status,
            rf_idempotency_key: rf_payment_response.idempotency_key,
        }
    );
    return response.data as PayByLinkResponse;
}

export async function CustomReportExport(createCustomExport: any): Promise<any> {
    try {
        const response = await axiosPrivateCommon.post(API_PATHS.CUSTOM_REPORT_EXPORT, createCustomExport);
        if (!response.data || response.data.length === 0) {
            return { success: false, message: "No data found with the given criteria." };
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'APT_Reports.csv'); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return { success: true };
    }
    catch (error) {
        return { success: false, message: "An error occurred while exporting the report." };
    }
}

export async function GetClientCodeData(): Promise<any> {
    try {
        const response = await axiosPrivateCommon.get(API_PATHS.GET_CLIENT_CODES);
        if (!response.data || response.data.length === 0) {
            return { success: false, message: "No data found." };
        }
        return { data: response.data, success: true };
    }
    catch (error) {
        return { success: false, message: "An error occurred while exporting the report." };
    }
}

export async function GetBillingContactData(): Promise<any> {
    try {
        const response = await axiosPrivateCommon.get(API_PATHS.GET_BILLINGCONTACT_CODES);
        if (!response.data || response.data.length === 0) {
            return { success: false, message: "No data found." };
        }
        return { data: response.data, success: true };
    }
    catch (error) {
        return { success: false, message: "An error occurred while exporting the report." };
    }
}

export async function GetClientBillingData(): Promise<any> {
    try {
        const response = await axiosPrivateCommon.post(API_PATHS.CLIENT_CODE);
        if (!response.data || response.data.length === 0) {
            return { success: false, message: "No data found." };
        }
        return response.data;
    }
    catch (error) {
        return { success: false, message: "An error occurred while fetching data." };
    }
}

export async function getDashboardGraphData(day: any): Promise<any> {
    try {
        const storedAuth = localStorage.getItem('auth');
        const userToken = storedAuth ? JSON.parse(storedAuth) : null;
        const clientChannel = userToken?.roles[0].split("::")[0];
        const response = await axiosPrivate.get(`${API_PATHS.GET_DASHBOARD_GRAPH}/${day}`, {
            headers: {
                'Content-Type': 'application/json',
                 authorization: `Bearer ${userToken?.accessToken}`,
                 'x-client-channel': clientChannel
            }
        });
        if (!response.data || response.data.length === 0) {
            return { success: false, message: "No data found." };
        }
        return response.data;
    }
    catch (error) {
        return { success: false, message: "An error occurred while fetching data." };
    }
}

export async function getDashboardExportData(day: any): Promise<any> {
    try {
        const storedAuth = localStorage.getItem('auth');
        const userToken = storedAuth ? JSON.parse(storedAuth) : null;
        const clientChannel = userToken?.roles[0].split("::")[0];
        const response = await axiosPrivate.get(`${API_PATHS.GET_DASHBOARD_EXPORT}/${day}`, {
            headers: {
                'Content-Type': 'application/json',
                 authorization: `Bearer ${userToken?.accessToken}`,
                 'x-client-channel': clientChannel
            }
        });
        if (!response.data || response.data.length === 0) {
            return { success: false, message: "No data found." };
        }
       
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'APT_Reports.csv'); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return { success: true };
    }
    catch (error) {
        return { success: false, message: "An error occurred while fetching data." };
    }
}

// export async function ContactFormRequest(contactFormRequestBody: any): Promise<any> {
//     try {
//         console.log("services",contactFormRequestBody);
//         const response = await axiosPrivateCommon.post(API_PATHS.POST_CONTACTFORM, contactFormRequestBody);
       
//         return { response};
//     }
//     catch (error) {
//         return { status: false, message: "An error occurred while sending the details." };
//     }
// }

export function ContactFormRequest(contactFormRequestBody: any): Promise<any> {
    console.log("services", contactFormRequestBody);
    
    return axiosPrivateCommon.post(API_PATHS.POST_CONTACTFORM, contactFormRequestBody)
        .then(response => {
            return { status: true, response }; // Use response.status if you have a specific status code check
        })
        .catch(error => {
            console.error("Error in ContactFormRequest:", error);
            return { status: false, message: "An error occurred while sending the details." };
        });
}