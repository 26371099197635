import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, Paper, Link, Modal, Grid, IconButton, FormControl, List, ListItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_PATHS, axiosPublic, ContactFormRequest, createPaymentTransaction } from '../../api/service';
import AdminNavigations from '../../components/navigations/AdminNavigations';
import StickyFooter from '../../components/StickyFooter';
import { useAuth } from '../../context';
import { THEME_KRI } from '../../assets/kri/theme';
import { PaymentTransaction, User } from '../../api/models';
import Navigations from "../../components/navigations/Navigations";
import CloseIcon from "@mui/icons-material/Close";

export interface ContactSupportFormData {
  firstname?: string;
  lastname?: string;
  companyname?: string;
  email: string;
  phone_number?: string;
  client_name: string;
  annual_processing_volume?: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "5px",
  width: 400,
  display: "flex",
  flexDirection: "column",
  gap: "0.4rem",
};

interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

export default function SignIn() {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [signInDisabled, setSignInDisabled] = useState(false);
  const [noDataOpen, setNoDataOpen] = useState(false);
  // const errRef = useRef<HTMLParagraphElement>(null);

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [companyname, setCompanyname] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [client_name, setClientName] = useState('');
  const [annual_processing_volume, setAnnualProcessingVolume] = useState('');
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const validateForm = (form_data: FormData) => {
    if (!form_data.get('username') || !form_data.get('password')) {
      setSignInDisabled(false);
      throw Error('Invalid username or password')
    }
  }

  function encodeBase64(password) {
    return btoa(password);
  }

  const initialState = {
    firstname: '',
    lastname: '',
    companyname: '',
    email: '',
    phone_number: '',
    client_name: '',
    annual_processing_volume: '',
  };
  const [localFormData, setLocalFormData] = useState<ContactSupportFormData>(initialState);

  const [errors, setErrors] = useState<Partial<ContactSupportFormData>>({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setSignInDisabled(true);
    try {
      const form_data = new FormData(event.currentTarget);
      validateForm(form_data);

      const plainPassword = form_data.get('password');  // Get password from user input
      const hashedPassword = encodeBase64(plainPassword);

      const response = await axiosPublic.post(API_PATHS.AUTH_TOKEN, {
        username: form_data.get('username'),
        // password: form_data.get('password'),
        password: hashedPassword,
      });

      const token_response = response.data as TokenResponse;
      const config = {
        headers: { Authorization: `Bearer ${token_response.access_token}` },
      };

      const user_response = await axiosPublic.get(
        API_PATHS.AUTH_USER_ME,
        config
      );
      const user = user_response?.data?.user as User;
      const scopes = user_response?.data?.scopes as string[];
      const isLoggedIn = true;
      const username_display =
        user.firstname && user.lastname
          ? `${user?.firstname} ${user?.lastname}`
          : user.username;

      setAuth({
        id: user.operator_id,
        username: username_display,
        isLoggedIn: isLoggedIn,
        roles: scopes,
        accessToken: token_response.access_token,
      });
      navigate('/admin');
    } catch (err) {
      setSignInDisabled(false);
      if (err?.response && (err.response?.status === 400 || err.response?.status === 401)) {
        // TODO - handle other error responses
        setErrorMessage('Login Failed: Invalid username or password');
      }
      else if (err?.message) {
        // setErrorMessage(`Login Failed: ${err.message}`);
        setErrorMessage('Login Failed: Invalid username or password');
      }
      else {
        setErrorMessage('Login Failed: Please contact support');
      }
    }
  };
  const handleNoDataClose = () => setNoDataOpen(false);

  const handleSupportClick = () => {
    setNoDataOpen(true);
    const url = new URL(window.location.href);
    const clientName = url.searchParams.get("client_name") || "";
    setClientName(clientName);
  };

  useEffect(() => {
    setAuth(null);
    localStorage.removeItem('auth')
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let error = '';
    let formattedValue = value;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    switch (name) {

      case 'phone_number':
        formattedValue = formattedValue.replace(/[^\d]/g, '');
        if (formattedValue.length > 3) {
          formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
        }
        if (formattedValue.length > 7) {
          formattedValue = `${formattedValue.slice(0, 7)}-${formattedValue.slice(7)}`;
        }
        formattedValue = formattedValue.slice(0, 15);
        break;

      case 'firstname':
      case 'lastname':
      case 'companyname':
        if (/[^a-zA-Z0-9\s]/.test(formattedValue)) {
          error = 'Special characters are not allowed';
        } else if (formattedValue.length < 1) {
          error = 'Field can not be empty';
        } else if (formattedValue.length > 40) {
          error = 'Maximum 40 characters allowed';
        }
        formattedValue = formattedValue.slice(0, 40);
        break;
      case 'annual_processing_volume':
        if (/[^0-9\s]/.test(formattedValue)) {
          error = 'Special characters are not allowed';
        } else if (formattedValue.length > 40) {
          error = 'Maximum 40 characters allowed';
        }
        formattedValue = formattedValue.slice(0, 40);
        break;
      case 'email':
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formattedValue)) {
          error = 'Please provide a valid email address';
        } else if (formattedValue.length > 100) {
          error = 'Email must be less than 100 characters';
        } else {
          const [local, domain] = formattedValue.split('@');
          if (local.length > 64 || (local + '@' + domain).length > 100) {
            error = 'Email is too long';
          }
        }
        formattedValue = formattedValue.slice(0, 100);

        break;
      default:
        break;
    }

    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleKeyDown = (e) => {
    const { name, value } = e.target;
    let error = '';
    let formattedValue = value;
    if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      return; // Allow these keys
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    switch (name) {
      case 'firstname':
      case 'lastname':
      case 'companyname':
        if (/[^a-zA-Z0-9\s]/.test(e.key)) {
          e.preventDefault();
        }
        break;
      case 'annual_processing_volume':
        if (/[^0-9\s]/.test(e.key)) {
          e.preventDefault();
        }
        break;
      case 'email':
        // Restrict entry to letters, numbers, periods, and @ only
        if (/[^a-zA-Z0-9.@]/.test(e.key)) {
          e.preventDefault();
        }
        break;
      case 'phone_number':
        // Allow only numeric input
        if (!/\d/.test(e.key)) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }

    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const handlePaste = (e) => {
    const { name, value } = e.target;
    let error = '';
    let formattedValue = value;
    if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      return; // Allow these keys
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    const pastedText = e.clipboardData.getData('text/plain');
    const containsNonNumeric = pastedText.split('').some((char) => isNaN(parseInt(char)));

    switch (name) {
      case 'firstname':
      case 'lastname':
      case 'companyname':
        if (/[^a-zA-Z\s]/.test(pastedText)) {
          e.preventDefault();
          error = 'Special characters are not allowed (Copied text includes special character)';
        }
        break;
      case 'annual_processing_volume':
        if (/[^0-9\s]/.test(pastedText)) {
          e.preventDefault();
          error = 'Special characters are not allowed (Copied text includes special character)';
        }
        break;
      case 'email':
        // Restrict entry to letters, numbers, periods, and @ only
        if (/[^a-zA-Z0-9.@]/.test(pastedText)) {
          e.preventDefault();
          error = 'Special characters are not allowed other than . @ (Copied text includes special character)';
        }
        break;
      case 'phone_number':
        // Allow only numeric input
        if (containsNonNumeric) {
          e.preventDefault();
          error = 'Only Numericals are allowed (Copied text includes non-numeric character)';
        }
        break;
      default:
        break;
    }

    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const handleContactFormSubmit = async () => {


    const requiredFields = [
      { field: 'firstname', value: firstname },
      { field: 'lastname', value: lastname },
      { field: 'email', value: email },
      { field: 'phone_number', value: phone_number },
      { field: 'companyname', value: companyname },
    ];

    const missing = requiredFields
      .filter(field => !field.value)
      .map(field => field.field);
    if (missing.length > 0) {
      setMissingFields(missing);
    } else {
      // Submit logic
      const contactFormRequestBody = {
        firstname: firstname || null,
        lastname: lastname || null,
        companyname: companyname || null,
        email: email || null,
        phone_number: phone_number || null,
        client_name: client_name || null,
        annual_processing_volume: annual_processing_volume || null,
      };

      ContactFormRequest(contactFormRequestBody)
        .then(response => {

          if (response.status) { // Check for the custom `status` key in returned object
            setNoDataOpen(false); // Close the modal
            setMissingFields([]); // Clear any error messages
            setLocalFormData(initialState);
          } else {
            setMissingFields([response.message]); // Display error message
          }
        })
        .catch(error => {
          setMissingFields(["An error occurred. Please try again."]);
        })
        .finally(() => {
          // setLoading(false); // End loading state
        });


      setMissingFields([]); // Clear errors if submit is successful
    }
  };

  const handleFirstnameChange = (e) => {
    setFirstname(e.target.value);
    handleChange(e);  // Call any additional logic here
  };

  const handleLastnameChange = (e) => {
    setLastname(e.target.value);
    handleChange(e);  // Call any additional logic here
  };

  const handleCompanynameChange = (e) => {
    setCompanyname(e.target.value);
    handleChange(e);  // Call any additional logic here
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    handleChange(e);  // Call any additional logic here
  };

  const handlesetPhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    handleChange(e);  // Call any additional logic here
  };

  const handleAnnualProcessingVolumeChange = (e) => {
    setAnnualProcessingVolume(e.target.value);
    handleChange(e);  // Call any additional logic here
  };

  return (
    <ThemeProvider theme={THEME_KRI}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <CssBaseline />
        <Navigations />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
          }}>
          <Toolbar />
          <Toolbar />
          <Container maxWidth="lg" >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="User Name"
                  name="username"
                  type="string"
                  autoComplete="username"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Box maxWidth="sm">
                  {/* <Container maxWidth="sm"> */}
                  {errorMessage && (
                    <Alert variant="outlined" severity="error">
                      {errorMessage}
                    </Alert>
                  )}
                  {/* </Container> */}
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={signInDisabled}
                  sx={{ mt: 2, mb: 2 }}>
                  Sign In
                </Button>
                <Typography variant="body2">
                  <Box display="flex" justifyContent="space-between">
                    <Link href="/forgot-password" color="primary">
                      Forgot password?
                    </Link>

                    <Link onClick={handleSupportClick} sx={{ textDecoration: 'none', cursor: 'pointer' }}> Don't have an APT account? </Link>
                  </Box>
                </Typography>
              </Box>
            </Box>

          </Container>
        </Box>
        <Paper
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            backgroundColor: '#0C415B',
            pt: 2,
            pb: 1,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            '@media (max-width: 600px)': {
              height: '50px',
            },
          }}
          component="footer"
          square
          variant="outlined">
          <StickyFooter />
        </Paper>
      </Box>
      <Modal open={noDataOpen} onClose={handleNoDataClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Contact form
            </Typography>
            <IconButton aria-label="close" onClick={handleNoDataClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Box component="form" sx={{
            mt: 2, display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
          }}></Box>
          <TextField
            name="firstname"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            size="small"
            value={localFormData.firstname}
            onChange={handleFirstnameChange}
            onKeyDown={(e) => handleKeyDown(e)}
            onPaste={(e) => handlePaste(e)}
            error={!!errors.firstname}
            helperText={errors.firstname}
          />
          <TextField
            name="lastname"
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            size="small"
            value={localFormData.lastname}
            onChange={handleLastnameChange}
            onKeyDown={(e) => handleKeyDown(e)}
            onPaste={(e) => handlePaste(e)}
            error={!!errors.lastname}
            helperText={errors.lastname}
          />
          <TextField
            name="companyname"
            label="Company Name"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            size="small"
            value={localFormData.companyname}
            onChange={handleCompanynameChange}
            onKeyDown={(e) => handleKeyDown(e)}
            onPaste={(e) => handlePaste(e)}
            error={!!errors.companyname}
            helperText={errors.companyname}
          />
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            required
            value={localFormData.email}
            onChange={handleEmailChange}
            onKeyDown={(e) => handleKeyDown(e)}
            onPaste={(e) => handlePaste(e)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            name="phone_number"
            label="Phone Number"
            variant="outlined"
            required
            fullWidth
            margin="normal"
            size="small"
            value={localFormData.phone_number}
            onChange={handlesetPhoneNumberChange}
            onPaste={(e) => handlePaste(e)}
            error={!!errors.phone_number}
            helperText={errors.phone_number}
          />
          <TextField
            name="annual_processing_volume"
            label="Annual processing volume"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            value={localFormData.annual_processing_volume}
            onChange={handleAnnualProcessingVolumeChange}
            onKeyDown={(e) => handleKeyDown(e)}
            onPaste={(e) => handlePaste(e)}
            error={!!errors.annual_processing_volume}
            helperText={errors.annual_processing_volume}
          />
          <Typography>

            {missingFields.length > 0 && (
              <Typography variant="body1" color="error" style={{ marginTop: '10px' }}>
                Please fill in the required fields:
                <List>
                  {missingFields.map(field => (
                    <ListItem key={field} style={{ padding: '0px', marginLeft: '16px' }}>
                      - {field}
                    </ListItem>
                  ))}
                </List>
              </Typography>
            )}
          </Typography>
          <Grid container justifyContent="center" spacing={2} sx={{ mt: 0 }}>
            <Grid item>
              <Button variant="contained" onClick={handleNoDataClose} sx={{ minWidth: '100px', minHeight: '40px' }}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained"
                sx={{ minWidth: '100px', minHeight: '40px' }} onClick={handleContactFormSubmit}> Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
